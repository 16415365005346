<template>
  <sm-master-layout>
      <div class="sm-finish">
        <ion-icon class="sm-finish__icon" :icon="checkmarkCircle" color="success" size="small" slot="start"/>
        <strong>BESTELLUNG ERFOLGREICH AUFGEGEBEN</strong>
      </div>

    <div class="sm-list-button">
      <ion-button expand="block" color="primary" @click="() => router.push({name: 'scan-qr'})">NEUE BESTELLUNG
        AUFGEBEN
      </ion-button>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import {
    IonButton,
    IonIcon,
} from '@ionic/vue';
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import { mapMutations } from "vuex";
import { useRouter } from 'vue-router';
import { alertCircle, checkmarkCircle } from "ionicons/icons";

export default defineComponent({
  name: 'Finish',

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    SmMasterLayout,
    IonButton,
    IonIcon,
  },

  setup() {
    const router = useRouter();
    return { router, alertCircle, checkmarkCircle };
  },

  ionViewWillEnter () {
    this.setHeaderTitle('Kasse / Bestellbestätigung');
    this.setNumberStep('3');
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle', 'setNumberStep']),
  }
})
</script>

<style scoped>
  .sm-finish {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 5rem;
  }

  .sm-finish__icon {
    margin-right: 10px;
  }
</style>
